.tableRowEven {
    background-color: #f7f7f7;
    cursor: pointer;
    text-decoration: none;
    color: inherit;                                        
}

.tableRowOdd {
    background-color: white;
    cursor: pointer;  
    text-decoration: none;
    color: inherit;                            
}
