// Dynamic Padding & Margin classes (pixels)
$gaps: (
	0,
	1,
	2,
	3,
	4,
	5,
	6,
	7,
	8,
	9,
	10,
	11,
	12,
	13,
	14,
	15,
	16,
	17,
	18,
	19,
	20,
	21,
	22,
	23,
	24,
	25,
	26,
	27,
	28,
	29,
	30,
	31,
	32,
	33,
	34,
	35,
	36,
	37,
	38,
	39,
	40,
	41,
	42,
	43,
	44,
	45,
	46,
	47,
	48,
	49,
	50,
	51,
	52,
	53,
	54,
	55,
	56,
	57,
	58,
	59,
	60,
	61,
	62,
	63,
	64,
	65,
	66,
	67,
	68,
	69,
	70,
	71,
	72,
	73,
	74,
	75,
	76,
	77,
	78,
	79,
	80,
	81,
	82,
	83,
	84,
	85,
	86,
	87,
	88,
	89,
	90,
	91,
	92,
	93,
	94,
	95,
	96,
	97,
	98,
	99,
	100,
	200
);
// Extend or adjust this array with values you want to use (in pixels)
$sides: (top, bottom, left, right);

@each $gap in $gaps {
	.m-#{$gap} {
		margin: #{$gap}px !important;
	}

	.p-#{$gap} {
		padding: #{$gap}px !important;
	}

	.grid-item.grid-item-#{$gap} {
		width: percentage($gap / 100);
	}

	@each $side in $sides {
		.m-#{str-slice($side, 0, 1)}-#{$gap} {
			margin-#{$side}: #{$gap}px !important;
		}

		.p-#{str-slice($side, 0, 1)}-#{$gap} {
			padding-#{$side}: #{$gap}px !important;
		}
	}
}

@media screen and (max-width: 768px) {
	@each $gap in $gaps {
		.ms-#{$gap} {
			margin: #{$gap}px !important;
		}

		.ps-#{$gap} {
			padding: #{$gap}px !important;
		}

		@each $side in $sides {
			.ms-#{str-slice($side, 0, 1)}-#{$gap} {
				margin-#{$side}: #{$gap}px !important;
			}

			.ps-#{str-slice($side, 0, 1)}-#{$gap} {
				padding-#{$side}: #{$gap}px !important;
			}
		}
	}
}

@media screen and (max-width: 992px) {
	@each $gap in $gaps {
		.mm-#{$gap} {
			margin: #{$gap}px !important;
		}

		.pm-#{$gap} {
			padding: #{$gap}px !important;
		}

		@each $side in $sides {
			.mm-#{str-slice($side, 0, 1)}-#{$gap} {
				margin-#{$side}: #{$gap}px !important;
			}

			.pm-#{str-slice($side, 0, 1)}-#{$gap} {
				padding-#{$side}: #{$gap}px !important;
			}
		}
	}
}
