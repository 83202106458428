@import "./dynamicHelperClasses.scss";

// Datacenter Review Styles
#review {
	max-width: 100vw;
	margin-bottom: 70px;
	@media all and (max-width: 992px) {
		padding: 0;
	}
	#reviewInner {
		width: 100%;
		margin-left: -16px;
		.MuiGrid-spacing-xs-2,
		.mobileReset {
			@media all and (max-width: 992px) {
				margin-right: 5px !important;
			}
		}
		.MuiGrid-spacing-xs-2 {
			@media all and (max-width: 992px) {
				width: calc(100% - 10px);
				margin-left: 5px !important;
			}
		}
		.mobileReset {
			@media all and (max-width: 992px) {
				margin-left: 0 !important;
				width: 100%;
			}
		}
		#buildInfo {
			> div {
				padding-left: 23px;
				padding-right: 25px;
			}
			.buildStats {
				font-size: 1rem;
				@media all and (max-width: 992px) {
					display: block;
					text-align: center;
				}
				svg {
					margin-right: 5px;
					margin-top: -5px;
					vertical-align: middle;
				}
			}
			.buildID {
				font-size: 1.25rem;
				vertical-align: middle;
				@media all and (max-width: 992px) {
					display: block;
					text-align: center;
					margin-bottom: 10px;
				}
				svg {
					margin-top: -5px;
					margin-right: 5px;
					vertical-align: middle;
				}
				#copyBuildID {
					display: inline-block;
					width: 15px;
					height: 15px;
					padding: 8px;
					margin-top: -5px;
					margin-bottom: 0px;
					margin-left: 5px;
				}
			}
			.MuiTypography-h5 {
				margin-top: -25px;
				padding-bottom: 5px;
				font-family: "Montserrat";
			}
			.minutes {
				margin-right: 15px;
			}
			.spacer {
				vertical-align: text-bottom;
				margin: 0 5px;
			}
			.vmPostInstallProgress {
				@media all and (max-width: 992px) {
					position: relative;
				}
				position: relative;
				.MuiTypography-h6 {
					display: inline-flex;
					margin-bottom: 5px;
					margin-top: 8px;
					span {
						color: #ea2331;
						font-style: italic;
						margin-left: 5px;
					}
					@media all and (max-width: 992px) {
						display: block;
						text-align: center;
					}
				}
				svg {
					margin-top: 2px;
					margin-right: 5px;
					@media all and (max-width: 992px) {
						margin-bottom: -5px;
					}
				}
				.MuiLinearProgress-colorPrimary {
					background-color: lightgrey !important;
					width: 50%;
					display: block !important;
					margin-left: auto !important;
					margin-right: auto !important;
					display: inline-flex;
					position: absolute;
					top: 21px;
					right: 5px;
					@media all and (max-width: 992px) {
						text-align: center;
						position: relative;
						top: 0;
					}
				}
				.MuiLinearProgress-barColorPrimary {
					background-color: #ea2331 !important;
				}
			}
		}
		#hostInfo {
			background-color: rgb(234, 234, 234);
			box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
				0px 1px 1px 0px rgba(0, 0, 0, 0.14),
				0px 1px 3px 0px rgba(0, 0, 0, 0.12);
			border-radius: 3px;
			width: 100%;
			margin: 30px auto 0;
			padding: 10px 7px 10px;
			.MuiPaper-elevation1 {
				height: 100%;
				.hostLink {
					text-transform: uppercase;
				}
			}
			.hostName {
				font-size: 1.25rem;
			}
		}
		#hostsTables {
			display: inline-block;
			margin-top: 15px;
			width: 100%;
			@media all and (max-width: 992px) {
				margin-top: 5px;
			}
			.MuiPaper-elevation1 {
				background-color: #2525251f;
				box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
					0px 1px 1px 0px rgba(0, 0, 0, 0.14),
					0px 1px 3px 0px rgba(0, 0, 0, 0.12);
				height: auto;
				> div {
					.MuiPaper-elevation1 {
						background-color: white;

						.MuiPaper-elevation1 {
							background-color: #eaeaea;
							border-bottom-right-radius: 3px;
							border-bottom-left-radius: 3px;
						}
					}
				}
			}
		}
		.card {
			padding: 5px 10px;
			.MuiTypography-h5 {
				width: 100%;
				padding-top: 10px;
				padding-bottom: 15px;
				text-indent: 5px;
				border-bottom: 2px solid rgb(234, 234, 234);
			}
			&.noData {
				width: 50vw;
				overflow: hidden;
				.MuiTypography-h5 {
					max-width: 93%;
				}
			}
			&.hosts {
				.MuiTypography-h5 {
					width: 100%;
					padding-top: 10px;
					padding-bottom: 15px;
					text-indent: 5px;
					border-bottom: 2px solid rgb(234, 234, 234);
				}
				.MuiAccordion-root {
					margin-bottom: 15px;
				}
				.MuiAccordionDetails-root {
					padding: 0;
					.MuiTableContainer-root {
						background-color: #eaeaea;
						border-radius: 0;
						padding: 0 0 20px;
						margin-bottom: 0;
						.MuiTableCell-head {
							font-weight: bold;
							font-size: 0.9rem;
							padding: 15px 30px 5px !important;
						}
						.MuiTableCell-root {
							font-size: 0.8rem;
							padding: 5px 30px 5px;
						}
					}
				}
				.MuiButtonBase-root {
					padding: 0 15px;
				}
				.MuiAccordionSummary-content {
					p {
						font-size: 1.25rem;
						margin-left: 0;
					}
				}
				.MuiGrid-grid-md-6 {
					@media all and (max-width: 992px) {
						margin-bottom: 15px;
					}
				}
			}
		}
		#reviewTitle {
			.MuiTypography-h4 {
				display: inline-block;
				margin-right: 15px;
				padding-top: 6px;
				text-indent: 15px;
				margin-bottom: 8px;
				@media all and (max-width: 992px) {
					display: block;
					text-align: center;
				}
			}
			#reviewButtons {
				text-align: center;
				margin-bottom: 10px;
				display: inline-block;
				vertical-align: bottom;
				margin-right: 10px;
				float: right;
				@media all and (max-width: 992px) {
					display: block;
					text-align: center;
					float: none;
				}
				button,
				a {
					margin-right: 10px;
				}

				.MuiSvgIcon-root {
					margin-right: 0;
				}
			}
			.MuiLinearProgress-colorPrimary {
				background-color: lightgrey;
				width: 50%;
				display: block !important;
				margin-left: auto !important;
				margin-right: auto !important;
			}
			.MuiLinearProgress-barColorPrimary {
				background-color: #ea2331;
			}
			.MuiSvgIcon-root {
				margin-right: 10px;
			}
			.MuiPaper-root {
				padding-top: 10px;
				padding-bottom: 5px;
			}
		}
	}
}
#logs,
#tasks {
	.MuiTypography-h6 {
		text-align: center;
		font-size: 26px;
		font-weight: bold;
		border-bottom: 1px solid #808080;
		margin-bottom: 15px;
	}
	.MuiDialog-container {
		width: 75vw;
		min-width: 75vw;
		margin: auto;
		.MuiPaper-root {
			max-width: 100%;
			min-width: 75vw;
		}
	}
}
