// Core
body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #800002;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Montserrat',
    monospace;
}

.MuiTypography-h5 {
  padding-top: 30px;
}

main {
	margin-bottom: 80px;
}
#ctaBanner {
	background: linear-gradient(
		90deg,
		#000 0%,
		rgb(180, 25, 35) 50%,
		#000 100%
	);
	border-bottom: 1px solid #000;
	padding: 0;
	margin-bottom: 0;
	text-align: center;
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	border-top: 1px solid rgb(255 255 255 / 15%);
	position: relative;
	z-index: 1;
	p {
		padding: 15px;
		margin: 0;
		color: white;
		text-shadow: rgba(0, 0, 0, 0.75) 1px 1px 1px;
		-webkit-font-smoothing: antialiased;
		a {
			text-shadow: none;
			font-weight: bold;
			color: white;
		}
	}
}
.dumb {
	animation-name: myAnimation;
	animation-duration: 5s;
}
.dumbSpinner {
	background-color: black;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	> div {
		margin: auto;
		img {
			width: 450px;
			margin-top: -75px;
			margin-left: -60px;
		}
	}
	svg {
		circle {
			stroke: #fff;
		}
	}
	#dumbSpinner {
		position: absolute;
		right: calc(50vw - 15px);
		top: calc(50vh + 20px);
	}
}
