#settingsLicense {
	.MuiTypography-h5 {
		text-align: center;
	}
	.MuiPaper-elevation1 {
		background-color: white !important;
		width: 100%;
		padding: 0 !important;
	}
	.MuiTablePagination-root {
		color: black !important;
	}
	.MuiTableCell-head {
		font-weight: bold;
		font-size: 1.05rem;
	}
	div {
		max-width: 50vw;
	}
	.refreshTable {
		color: black !important;
	}
	form {
		text-align: center;
		button {
			margin: 10px auto;
		}
		> div {
			> div {
				display: inline-flex;
				align-items: center;
				margin-bottom: 10px;
				margin-left: 10px;
			}
		}
	}
	.MuiTableContainer-root {
		margin: 0 auto 10px;
		width: 100vw;
	}
}
.MuiLinearProgress-colorPrimary {
	background-color: lightgrey;
	width: 50%;
	// display: block !important;
	margin-left: auto !important;
	margin-right: auto !important;
}
.MuiLinearProgress-barColorPrimary {
	background-color: #ea2331;
}
.deviceLicenses {
	.MuiPaper-root {
		margin-top: -20px;
	}
	.refreshTable {
		margin-bottom: 10px;
	}
}
